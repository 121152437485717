import "./ButtonPopup.css";
import React, { useState, useEffect } from "react";
import { CATEGORIES } from "../../utils/constants.js";

const ACCESS_LEVELS = ["free", "user", "admin"];

const ButtonPopup = ({
  button,
  onClose,
  handleUpdateButton,
  handleCreateButton,
}) => {
  const {
    category,
    access,
    name,
    script,
    argument,
    icon,
    htmlClass,
    help,
    _id,
  } = button || {};

  const { eng, ru } = help || {};

  const isButtonPage = window.location.pathname === "/buttons";
  const isPPButtonPage = window.location.pathname === "/ppbuttons";

  const [selectedCategory, setSelectedCategory] = useState(category);
  const [selectedAccess, setSelectedAccess] = useState(access);

  useEffect(() => {
    setSelectedCategory(category || "");
    setSelectedAccess(access || "free"); // Default to 'free' if no access level is set
  }, [category, access]);

  const handleUpdateClick = () => {
    let updatedData = {};

    const nameValue = document.getElementById("name").value;
    const scriptValue = document.getElementById("script").value;
    const argumentValue = document.getElementById("argument").value;
    const iconValue = document.getElementById("icon").value;

    if (nameValue) updatedData.name = nameValue;
    if (scriptValue) updatedData.script = scriptValue;
    if (argumentValue) updatedData.argument = argumentValue;
    if (iconValue) updatedData.icon = iconValue;

    if (isButtonPage) {
      const htmlClassValue = document.getElementById("htmlClass").value;
      const engValue = document.getElementById("eng").value;
      const ruValue = document.getElementById("ru").value;

      if (htmlClassValue) updatedData.htmlClass = htmlClassValue;

      if (engValue || ruValue) {
        updatedData.help = {};
        if (engValue) updatedData.help.eng = engValue;
        if (ruValue) updatedData.help.ru = ruValue;
      }

      updatedData.category = selectedCategory;
      updatedData.access = selectedAccess;
    }

    const urlSegments = window.location.pathname.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];

    if (_id) {
      handleUpdateButton(_id, updatedData, lastSegment);
    } else {
      handleCreateButton(updatedData, lastSegment);
    }

    onClose();
  };

  return (
    <>
      <div className="user-popup__overlay" onClick={onClose}></div>
      <div className="user-popup">
        {isButtonPage && (
          <>
            <div className="user-card__category">
              {CATEGORIES.map((cat) => (
                <div
                  key={cat}
                  className={
                    selectedCategory === cat
                      ? "user-popup__category-active"
                      : "user-popup__category-inactive"
                  }
                  onClick={() => setSelectedCategory(cat)}
                >
                  {cat}
                </div>
              ))}
            </div>
            <div className="user-card__access">
              {ACCESS_LEVELS.map((level) => (
                <div
                  key={level}
                  className={
                    selectedAccess === level
                      ? "user-popup__access-active"
                      : "user-popup__access-inactive"
                  }
                  onClick={() => setSelectedAccess(level)}
                >
                  {level}
                </div>
              ))}
            </div>
          </>
        )}
        <input
          type="text"
          id="name"
          defaultValue={name || ""}
          placeholder="Name"
        />
        <input
          type="text"
          id="script"
          defaultValue={script || ""}
          placeholder="Script"
        />
        <input
          type="text"
          id="argument"
          defaultValue={argument || ""}
          placeholder="Argument"
        />
        <input
          type="text"
          id="icon"
          defaultValue={icon || ""}
          placeholder="Icon"
        />
        {isButtonPage && (
          <input
            type="text"
            id="htmlClass"
            defaultValue={htmlClass || ""}
            placeholder="HTML Class"
          />
        )}
        {isButtonPage && (
          <>
            <input
              type="text"
              id="eng"
              defaultValue={eng || ""}
              placeholder="Help English"
            />
            <input
              type="text"
              id="ru"
              defaultValue={ru || ""}
              placeholder="Help Russian"
            />
          </>
        )}
        <button onClick={handleUpdateClick} className="user-popup__update-btn">
          {_id ? "Обновить" : "Создать"}
        </button>
      </div>
    </>
  );
};

export default ButtonPopup;
